import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';

const CanonicalLink: FC = () => {
  const router = useRouter();
  const canonicalUrl = `https://www.snap2pass.com${router.asPath.split('?')[0]}`;

  return (
    <Head>
      <link rel="canonical" href={canonicalUrl} />
    </Head>
  );
};

export default CanonicalLink;